import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import {
  SiteMetadata,
  GoogleAds,
  TypeButtons,
} from '../components';
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { Layout } from '../layouts/Layout';

export default props => {
  const { data, location } = props;
  const {
    title,
    subheading,
    disclaimer,
    contentNode: {
      childMarkdownRemark: {
        html
      }
    },
    featureImage: {
      url,
      fluid
    },
  } = data.item;

  const navigation = location.state ? location.state.navigation : null;

  return (
    <Layout navigation={navigation}>
      <SiteMetadata
        title={title}
        description={'content'}
        image={url}
        path={props.location.pathname}
      />
      <article className="blog-post container flex flex-row pt-4">
        <div className='w-full md:w-5/6'>
          <div className="pb-6">
            <h1 className="text-2xl lg:text-3xl text-indigo-800 font-bold leading-tight">
              {title}
            </h1>
            {subheading && <h2 className="text-base text-gray-500 leading-normal mt-2">
              {subheading}
            </h2>}
            {disclaimer && <h4 className="text-xs text-gray-500 leading-tight italic">
              {disclaimer}
            </h4>}
          </div>
          <div className="flex flex-wrap mb-6">
            <div className="w-full">
              <Img fluid={fluid}
                alt={title}
                className="mb-8 lg:mx-12 mx-auto"
                imgStyle={{ objectFit: 'contain' }} />
            </div>
            <div className="w-full whitespace-pre-line text-base leading-normal text-indigo-900 pb-6 content"
              dangerouslySetInnerHTML={{ __html: html }}>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <OutboundLink href="https://www.awin1.com/cread.php?s=603019&v=1117&q=297409&r=268283"
              target="_blank"
              rel="noopener noreferrer">
              <img
                src="https://www.awin1.com/cshow.php?s=603019&v=1117&q=297409&r=268283"
                border="0"
                alt="usb microphone"
              />
            </OutboundLink>
          </div>
        </div>
        <div className="hidden md:flex mx-12 mt-8 justify-start flex-col w-1/6 items-center">
        {/* <div className="flex mx-12 mt-8 justify-start flex-col w-1/6 items-center"> */}
          <OutboundLink href="https://www.awin1.com/cread.php?s=602963&v=1117&q=297375&r=268283"
            className="py-4"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src="https://www.awin1.com/cshow.php?s=602963&v=1117&q=297375&r=268283"
              border="0"
              alt="wide range of acoustics"
            />
          </OutboundLink>
          {/* <GoogleAds slotId="4032855682"></GoogleAds> */}
          <OutboundLink href="https://www.awin1.com/cread.php?s=602993&v=1117&q=297395&r=268283"
            className="py-4"
            target="_blank"
            rel="noopener noreferrer">
            <img
              src="https://www.awin1.com/cshow.php?s=602993&v=1117&q=297395&r=268283"
              border="0"
              alt="wide range of pianos"
            />
          </OutboundLink>
        </div>
      </article>
      <GoogleAds slotId="3308164083"></GoogleAds>
      <TypeButtons title="You might also be interested in..."></TypeButtons>
    </Layout>
  );
};

export const query = graphql`
  query SingleBlogPostQuery($slug: String!) {
    item: datoCmsBlogpost(slug: { eq: $slug }) {
      title,
      subheading,
      disclaimer,
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      featureImage {
        url,
        fluid(maxWidth: 400) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
